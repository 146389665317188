<!-- eslint-disable vue/valid-v-slot -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <Layout>
    <v-row class="text-no-wrap">
      <v-col :cols="layoutColumn">
        <v-card flat outlined>
          <v-data-table
            :headers="headers"
            :items="clients"
            :search="search"
            class="elevation-0"
            outlined
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Risk</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Risk</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <RiskDetails
        :item="item"
        :is-second-column="isSecondColumn"
        @closeSecondColumn="closeSecondColumn"
        @refreshRisk="getAllRisks"
      />
    </v-row>
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import api from '@src/api/index.js'
import EventBus from '@utils/EventBus'
import RiskDetails from '@components/Risk/RiskDetails.vue'
import { EventBusEvents } from '@src/constants/index.js'
export default {
  components: {
    Layout,
    RiskDetails,
  },
  props: {
    clientType: {
      type: String,
      default: 'individual',
    },
    clientTypes: {
      type: Object,
      default: function () {
        return {
          INDIVIDUAL: 'individual',
          CORPORATE: 'corporate',
        }
      },
    },
    step: {
      type: [String, Number],
      default: 5,
    },
  },
  data() {
    return {
      search: '',
      item: null,
      loading: false,
      isSecondColumn: false,
      layoutColumn: 12,
      headers: [
        {
          text: 'Clients',
          align: 'left',
          value: 'clientName',
        },
        { text: 'Risk Factor', value: 'riskCategory', align: 'center' },
        { text: 'F2F Meeting', value: 'ftfMeeting', align: 'center' },
        { text: 'Check Review', value: 'checkReviewDone', align: 'center' },
        { text: 'Remarks', value: 'remarks', align: 'center' },
        // { text: 'DowJones', value: 'remarks', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      clients: [],
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  created() {
    this.getAllRisks()
  },
  methods: {
     remarks({ remarks }) {
      return remarks || 'n/a'
    },
    name({ clientName, companyName }) {
      return clientName || companyName
    },
    isReviewDone({ checkReviewDone }) {
      if (typeof checkReviewDone !== 'boolean') return 'n/a'
      return checkReviewDone ? 'Yes' : 'No'
    },
    riskFactor({ riskCategory }) {
      const risk = parseInt(riskCategory)
      switch (risk) {
        case 0:
          return 'No Risk'
        case 1:
          return 'Low'
        case 2:
          return 'Medium'
        case 3:
          return 'High'
        default:
          return 'n/a'
      }
    },
    f2fMeeting({ ftfMeeting }) {
      if (typeof ftfMeeting !== 'boolean') return 'n/a'
      return ftfMeeting ? 'Yes' : 'No'
    },
    closeSecondColumn() {
      this.isSecondColumn = false
      this.layoutColumn = 12
    },
    openSecondColumn() {
      this.isSecondColumn = true
      this.layoutColumn = 6
    },
    async getAllRisks() {
      this.loading = true
      try {
        const clients = await api.getAllRisks()
        clients.forEach(element => {
          if(element) {
            
            element.clientName= this.name(element);
            element.riskCategory = this.riskFactor(element);

            element.ftfMeeting = this.f2fMeeting(element);

            element.checkReviewDone = this.isReviewDone(element);

            element.remarks = this.remarks(element);
          }

        });
        this.clients = clients
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    editItem(item) {
      this.item = item
      this.isSecondColumn = true
      this.layoutColumn = 6
    },
  },
}
</script>
<style>
</style>
