<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-scroll-x-reverse-transition>
    <v-col v-show="isSecondColumn" cols="6">
      <v-form ref="riskDetailsForm">
        <v-card flat outlined>
          <v-row class="px-10" align="center">
            <v-col cols="12">
              <v-slider
                v-model="riskCategory"
                label="Risk Category"
                :tick-labels="riskCategories"
                always-dirty
                :color="color"
                step="1"
                min="0"
                max="3"
                ticks="always"
                tick-size="4"
                class="mt-6"
              >
                <template v-slot:thumb-label="props">
                  <v-icon dark>{{ icon(props.value) }}</v-icon>
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-row class="px-10">
            <v-col cols="6">
              <v-checkbox
                v-model="ftfMeeting"
                color="red darken-4"
                label="Face to face meeting"
              ></v-checkbox>
            </v-col>
            <v-col cols="6">
              <v-checkbox
                v-model="checkReviewDone"
                color="red darken-4"
                label="Check Review Done"
              ></v-checkbox>
            </v-col>
            <v-col v-if="checkReviewDone" cols="6">
              <v-menu
                ref="menuActualReview"
                v-model="menuActualReview"
                :close-on-content-click="false"
                :return-value.sync="actualReview"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="actualReview"
                    outlined
                    color="red darken-4"
                    dense
                    label="Actual Review"
                    prepend-icon="mdi-bell-plus-outline"
                    readonly
                    class="pb-0"
                    :error-messages="
                      requiredError($v.actualReview, 'Actual Review')
                    "
                    v-on="on"
                    @change="$v.actualReview.$touch"
                    @blur="$v.actualReview.$touch"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="actualReview"
                  color="red darken-4"
                  no-title
                  scrollable
                >
                  <div class="flex-grow-1"></div>
                  <v-btn
                    text
                    color="red darken-4"
                    @click="menuActualReview = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="red darken-4"
                    @click="$refs.menuActualReview.save(actualReview)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menuNextReview"
                v-model="menuNextReview"
                :close-on-content-click="false"
                :return-value.sync="nextReview"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="nextReview"
                    outlined
                    color="red darken-4"
                    dense
                    label="Next Review"
                    prepend-icon="mdi-bell-plus-outline"
                    readonly
                    class="pb-0"
                    :error-messages="nextReviewErrors"
                    v-on="on"
                    @change="$v.nextReview.$touch"
                    @blur="$v.nextReview.$touch"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="nextReview"
                  color="red darken-4"
                  no-title
                  scrollable
                >
                  <div class="flex-grow-1"></div>
                  <v-btn
                    text
                    color="red darken-4"
                    @click="menuNextReview = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    text
                    color="red darken-4"
                    @click="$refs.menuNextReview.save(nextReview)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class="px-10">
            <v-col cols="12">
              <v-textarea
                v-model="description"
                color="red darken-4"
                auto-grow
                outlined
                label="Description of business activities and geographical risk "
                class="mt-10"
                :error-messages="requiredError($v.description, 'Description')"
                @change="$v.description.$touch"
                @blur="$v.description.$touch"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="px-10">
            <v-col cols="12">
              <v-textarea
                v-model="remarks"
                color="red darken-4"
                outlined
                label="Remarks"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="px-10">
            <v-col cols="12">
              <v-expansion-panels v-model="panel" flat>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >DowJones Details</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    Coming soon...
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2">
              <v-btn
                color="red darken-4"
                class="text-center"
                dark
                @click="close"
                >Cancel</v-btn
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                color="green darken-4"
                class="justify-self-center"
                dark
                @click="save"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { isSameOrAfter, validateError } from '@utils/validateFields'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  mixins: [validationMixin],
  props: {
    isSecondColumn: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: ['mdi-snowflake', 'mdi-leaf', 'mdi-alert', 'mdi-fire'],
      ftfMeeting: false,
      description: '',
      menuCheckReview: '',
      menuNextReview: '',
      menuActualReview: '',
      actualReview: '',
      nextReview: '',
      checkReviewDone: false,
      remarks: '',
      riskCategory: 0,
      riskCategories: ['0. No Risk', '1. Low', '2. Medium', '3. High'],
      panel: false,
    }
  },
  computed: {
    color() {
      switch (this.riskCategory) {
        case 1:
          return 'green'
        case 2:
          return 'orange'
        case 3:
          return 'red'
        default:
          return 'indigo'
      }
    },
    nextReviewErrors() {
      if (this.checkReviewDone) {
        return this.requiredAndDateErrors(this.$v.nextReview, 'Next Review')
      }
      return this.requiredError(this.$v.nextReview, 'Next Review')
    },
  },
  watch: {
    item: {
      deep: true,
      handler(newValue) {
        if (newValue && Object.keys(newValue).length !== 0) {
          this.riskCategory = newValue.riskCategory
          this.ftfMeeting = newValue.ftfMeeting
          this.checkReviewDone = newValue.checkReviewDone
          this.actualReview = newValue.actualReview || ''
          this.nextReview = newValue.nextReview
          this.description = newValue.description
          this.remarks = newValue.remarks
        }
      },
    },
  },
  validations() {
    return {
      actualReview: { required: requiredIf(() => this.checkReviewDone) },
      nextReview: {
        required,
        ...(this.checkReviewDone && {
          isSameOrAfter: isSameOrAfter(this.actualReview),
        }),
      },
      description: { required },
    }
  },
  methods: {
    icon(val) {
      return this.icons[val]
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
    requiredAndDateErrors(field, fieldName) {
      return validateError(
        field,
        ['required', 'isSameOrAfter'],
        `${fieldName} is required and should be after the previous date`
      )
    },
    close() {
      // reset only if new client
      if (!this.item.riskId) {
        this.$refs.riskDetailsForm.reset()
      }
      this.$emit('closeSecondColumn')
    },
    async createRisk(payload) {
      this.loading = true
      try {
        await api.createRisk(payload)
        this.loading = false
        this.close()
        this.$emit('refreshRisk')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    async updateRisk(payload) {
      this.loading = true
      try {
        await api.updateRisk(payload)
        this.loading = false
        this.close()
        this.$emit('refreshRisk')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: this.item.riskId ? 'Update Risk' : 'Create Risk',
          message: `Are you sure to ${
            this.item.riskId ? 'update' : 'create'
          } risk?`,
          confirmAction: () => this.saveRisk(),
        })
      }
    },
    saveRisk() {
      const payload = {
        riskCategory: this.riskCategory,
        ftfMeeting: !!this.ftfMeeting,
        checkReviewDone: !!this.checkReviewDone,
        actualReview: this.actualReview,
        nextReview: this.nextReview,
        description: this.description,
        remarks: this.remarks,
        clientId: this.item.clientId,
        ...(this.item.riskId ? { riskId: this.item.riskId } : {}),
      }

      return this.item.riskId
        ? this.updateRisk(payload)
        : this.createRisk(payload)
    },
  },
}
</script>
<style>
</style>